import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmDeletePhotosModal = ({
  show,
  onHide,
  photoCount,
  onConfirmDelete,
}) => {
  const handleDelete = () => {
    onConfirmDelete();
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete {photoCount} photo
          {photoCount !== 1 ? "s" : ""}?
        </p>
        <p className="text-danger">This action cannot be undone.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeletePhotosModal;
