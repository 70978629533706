import React, { useState, useEffect, useRef } from "react";
import "./ImageGrid.css";
import RightClickMenu from "./RightClickMenu";

const ImageGrid = ({
  // Array of image objects with thumbnail and optional alt properties
  images,
  // Selection state is plubmed through the parent component
  selectedImages,
  setSelectedImages,
  // Optional lambda function to group images by
  groupByLambda,
  // Callbacks for context menu actions
  handleContextMenuMove,
  handleContextMenuDelete,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [endPoint, setEndPoint] = useState({ x: 0, y: 0 });
  const [contextMenu, setContextMenu] = useState(null);
  const selectionRef = useRef(null);
  const imageRefs = useRef([]);

  const groupedImages = groupByLambda
    ? images.reduce((acc, img) => {
        const key = groupByLambda(img);
        if (!acc[key]) acc[key] = [];
        acc[key].push(img);
        return acc;
      }, {})
    : { "All Images": images };

  const handleImageClick = (image, event) => {
    if (event.ctrlKey || event.metaKey) {
      setSelectedImages((prev) => {
        return prev.includes(image)
          ? prev.filter((img) => img !== image)
          : [...prev, image];
      });
    } else {
      setSelectedImages([image]);
    }
  };

  const handleMouseDown = (e) => {
    if (e.button === 0) {
      // Left click
      setIsDragging(true);
      setStartPoint({ x: e.clientX, y: e.clientY });
      setEndPoint({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setEndPoint({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
      const selectionWidth = Math.abs(endPoint.x - startPoint.x);
      const selectionHeight = Math.abs(endPoint.y - startPoint.y);

      if (selectionWidth < 2 && selectionHeight < 2) {
        return;
      }

      const selection = selectionRef.current.getBoundingClientRect();
      const selectedImgs = imageRefs.current
        .filter((ref) => {
          const rect = ref.getBoundingClientRect();
          return (
            rect.left < selection.left + selection.width &&
            rect.right > selection.left &&
            rect.top < selection.top + selection.height &&
            rect.bottom > selection.top
          );
        })
        .map((ref) => images.find((img) => img.thumbnail === ref.src));
      setSelectedImages(selectedImgs);
    }
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    setContextMenu(
      contextMenu === null ? { x: e.clientX, y: e.clientY } : null
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenu && !event.target.closest(".context-menu")) {
        setContextMenu(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu]);

  return (
    <div
      className="relative"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onContextMenu={handleContextMenu}
    >
      {Object.entries(groupedImages).map(([group, groupImages]) => (
        <div key={group} className="grid-container">
          <h2 className="grid-title">{group}</h2>
          <div className="image-grid">
            {groupImages.map((image, index) => (
              <div
                key={index}
                className={`image-item ${
                  selectedImages.includes(image) ? "selected" : ""
                }`}
                onClick={(e) => handleImageClick(image, e)}
              >
                <img
                  src={image.thumbnail}
                  alt={image.alt}
                  ref={(el) => (imageRefs.current[index] = el)}
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>
      ))}
      {isDragging && (
        <div
          style={{
            left: Math.min(startPoint.x, endPoint.x),
            top: Math.min(startPoint.y, endPoint.y),
            width: Math.abs(endPoint.x - startPoint.x),
            height: Math.abs(endPoint.y - startPoint.y),
          }}
          ref={selectionRef}
          className="selection"
        />
      )}
      {contextMenu && (
        <RightClickMenu
          xPos={contextMenu.x}
          yPos={contextMenu.y}
          onClose={() => setContextMenu(null)}
        >
          <li onClick={handleContextMenuMove}>Move</li>
          <li onClick={handleContextMenuDelete}>Delete</li>
        </RightClickMenu>
      )}
    </div>
  );
};

export default ImageGrid;
