import { useState } from "react";
import "./PhotoList.css";
import Toolbar from "./Toolbar";
import Downloader from "./Downloader";
import Sidebar from "./Sidebar";
import {
  useApiAssets,
  useApiAlbums,
  createAlbum,
  assetsSetAlbum,
  deleteAssets,
} from "./apiHooks";
import CreateAlbumModal from "./CreateAlbumModal";
import MoveToAlbumModal from "./MoveToAlbumModal";
import ConfirmDeletePhotosModal from "./ConfirmDeletePhotosModal";
import ImageGrid from "./ImageGrid";
import { Button } from "react-bootstrap";
import Pagination from "./Pagination";

const getYear = (timestamp) => {
  const date = new Date(timestamp);
  return new Date(date).getFullYear();
};

const PhotoList = ({ handleLogout }) => {
  const { albums, fetchAlbums } = useApiAlbums();
  const [currentAlbum, setCurrentAlbum] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { assets, setAssets, totalPages } = useApiAssets(
    currentAlbum,
    100,
    currentPage
  );
  const [selectedImages, setSelectedImages] = useState([]); // Images and assets are used interchangeably
  const [showDownloader, setShowDownloader] = useState(false);
  const [showCreateAlbumModal, setShowCreateAlbumModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSelectedImages([]); // Clear selection when changing pages
    setAssets([]);
  };

  const handleCreateAlbum = ({ title, parentId }) => {
    const innerAsync = async () => {
      await createAlbum({ title, parentAlbum: parentId });
      fetchAlbums();
    };

    innerAsync();
  };
  const closeCreateAlbum = () => {
    setShowCreateAlbumModal(false);
  };

  const handleDownloadClick = () => {
    setShowDownloader(true);
  };

  return (
    <div className="layout-wrapper">
      <Toolbar
        onDownloadClick={handleDownloadClick}
        onFetchClick={() => setCurrentAlbum(null)}
        onLogoutClick={handleLogout}
      />
      <div className="layout-container">
        <div className="layout-sidebar">
          <Sidebar
            albums={albums}
            setShowCreateAlbumModal={setShowCreateAlbumModal}
            handleAlbumClick={(album) => {
              setCurrentAlbum(album.id);
            }}
          />
          {currentAlbum && (
            <Button color="secondary" onClick={() => setCurrentAlbum(null)}>
              Show All
            </Button>
          )}
        </div>
        <div className="layout-content">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
          <ImageGrid
            images={assets}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            groupByLambda={(image) => getYear(image.createdTimestamp)}
            handleContextMenuMove={() => setShowMoveModal(true)}
            handleContextMenuDelete={() => setShowDeleteModal(true)}
          />
        </div>
      </div>

      {showDownloader && (
        <Downloader
          photos={assets.filter((photo) => photo.selected)}
          onClose={() => setShowDownloader(false)}
        />
      )}
      {showCreateAlbumModal && (
        <CreateAlbumModal
          albums={albums}
          onSubmit={handleCreateAlbum}
          onCancel={closeCreateAlbum}
        />
      )}
      {showMoveModal && (
        <MoveToAlbumModal
          show={showMoveModal}
          onHide={() => setShowMoveModal(false)}
          albums={albums}
          onAlbumSelect={(album) => {
            assetsSetAlbum(selectedImages, album.id);
            // Remove assets from current album in the UI
            setAssets(
              assets.filter((asset) => !selectedImages.includes(asset))
            );
            setSelectedImages([]);
          }}
        />
      )}
      {showDeleteModal && (
        <ConfirmDeletePhotosModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          photoCount={selectedImages.length}
          onConfirmDelete={() => {
            deleteAssets(selectedImages);
            // Remove assets from current album in the UI
            setAssets(
              assets.filter((asset) => !selectedImages.includes(asset))
            );
            setSelectedImages([]);
          }}
        />
      )}
    </div>
  );
};

export default PhotoList;
