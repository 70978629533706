import "./RightClickMenu.css"; // CSS file for styling

const RightClickMenu = ({ xPos, yPos, children }) => {
  // Calculate the position of the menu based on click coordinates
  const menuStyle = {
    top: yPos,
    left: xPos,
  };

  return (
    <div className="right-click-menu" style={menuStyle}>
      <ul>
        {children.map((child, i) => (
          <div key={i}>{child}</div>
        ))}
      </ul>
    </div>
  );
};

export default RightClickMenu;
