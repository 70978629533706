import { useEffect, useState } from "react";
import config from "./config";
import supabase from "./supabase";

// If currentAlbum is null, fetch all assets. If it's not null, fetch assets for that album.
export const useApiAssets = (currentAlbum, limit, currentPage) => {
  const [photos, setPhotos] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  // Fetch assets when currentAlbum changes
  useEffect(() => {
    const fetchPhotos = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error !== null) {
        return;
      }

      // If currentAlbum is null, fetch all assets. If it's not null, fetch assets for that album.
      let url = config.backendUrl + "/assets";
      const params = {};
      if (currentAlbum) {
        params.albumId = currentAlbum;
      }
      params.limit = limit;
      params.page = currentPage;
      const queryString = new URLSearchParams(params).toString();
      url += "?" + queryString;

      // Fetch assets
      const res = await fetch(url, {
        headers: {
          Authorization: "Bearer " + data.session.access_token,
        },
      });

      const j = await res.json();
      setPhotos(j.photos);
      setTotalPages(j.totalPages);
    };

    fetchPhotos();
  }, [currentAlbum, limit, currentPage]);

  return { assets: photos, setAssets: setPhotos, totalPages };
};

export const useApiAlbums = () => {
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    fetchAlbums();
  }, []);

  const fetchAlbums = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (error !== null) {
      return;
    }

    const res = await fetch(config.backendUrl + "/albums", {
      headers: {
        Authorization: "Bearer " + data.session.access_token,
      },
    });

    const j = await res.json();
    setAlbums(j);
  };

  return { albums, fetchAlbums };
};

export const createAlbum = async ({ title, parentAlbum }) => {
  const { data, error } = await supabase.auth.getSession();
  if (error !== null) {
    return;
  }

  const res = await fetch(config.backendUrl + "/albums", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + data.session.access_token,
    },
    body: JSON.stringify({ title, ...(parentAlbum && { parentAlbum }) }),
  });

  const createdAlbum = await res.json();
  return createdAlbum;
};

export const assetSetAlbum = async (assetId, albumId) => {
  const { data, error } = await supabase.auth.getSession();
  if (error !== null) {
    return;
  }

  const res = await fetch(config.backendUrl + "/assets/" + assetId, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + data.session.access_token,
    },
    body: JSON.stringify({ albumId }),
  });

  const updatedAsset = await res.json();
  return updatedAsset;
};

export const assetsSetAlbum = async (assets, albumId) => {
  const { data, error } = await supabase.auth.getSession();
  if (error !== null) {
    return;
  }

  for (const asset of assets) {
    await fetch(config.backendUrl + "/assets/" + asset.id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.session.access_token,
      },
      body: JSON.stringify({ albumId }),
    });
  }
};

export const deleteAssets = async (assets) => {
  const { data, error } = await supabase.auth.getSession();
  if (error !== null) {
    return;
  }

  for (const asset of assets) {
    await fetch(config.backendUrl + "/assets/" + asset.id, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + data.session.access_token,
      },
    });
  }
};
