import React, { useState } from "react";
import { Modal, Button, ListGroup } from "react-bootstrap";

const MoveToAlbumModal = ({ show, onHide, albums, onAlbumSelect }) => {
  const [selectedAlbum, setSelectedAlbum] = useState(null);

  const handleAlbumSelect = (album) => {
    setSelectedAlbum(album);
  };

  const handleMove = () => {
    if (selectedAlbum) {
      onAlbumSelect(selectedAlbum);
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Move to Album</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {albums.map((album, index) => (
            <ListGroup.Item
              key={index}
              action
              onClick={() => handleAlbumSelect(album)}
              active={selectedAlbum === album}
            >
              {album.title}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleMove}
          disabled={!selectedAlbum}
        >
          Move
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MoveToAlbumModal;
